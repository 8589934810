<script setup lang="ts">

// META

// HOOKS
const mail = useMail();
const router = useRouter();

// DATA
const subject = ref('');
const firstName = ref('');
const lastName = ref('');
const email = ref('');
const bookCall = ref(false);
const loading = ref(false);
const honeypot = ref(''); // Honeypot field


// METHODS
const sendMail = async () => {
    if (honeypot.value !== '') {
        console.warn('Honeypot triggered, form submission ignored.');
        return;
    }

    try {
        loading.value = true;
        await mail.send({
            from: 'Ndaku',
            subject: 'New Contact Form Submission',
            html: `
      <div style="font-family: Arial, sans-serif; padding: 30px; color: #333; background-color: #F5F5F5; border-radius: 24px;">
        <h2 style="color: #532031;">Ndaku - New Contact</h2>
        <p><strong>Name:</strong> ${firstName.value} ${lastName.value}</p>
        <p><strong>Email:</strong> ${email.value}</p>
        <p><strong>Subject:</strong> <br> ${subject.value}</p>
        <p><strong>Wants to book a call ?:</strong> ${bookCall.value ? 'Yes' : 'No'}</p>
      </div>
      `
        });

        loading.value = false;
        if (honeypot.value !== '') {
            console.warn('Honeypot triggered, form submission ignored.');
            return;
        }
        router.push('/contact/emailSent');
    } catch (error: string | any) {
        console.error('Error:', error);
        loading.value = false;
        alert('An error occurred while sending your message. Please try again later.');
    }
};


const emailRules = [
    (val: string) => (val && val.length > 0) || 'ce champ est obligatoire',
    (val: string) => /.+@.+\..+/.test(val) || 'E-mail must be valid',
];

</script>

<template>
    <section class="my-8 py-8 lg:flex lg:justify-center">
        <div
            class="mx-1 h-3/4 bg-white grid lg:grid-cols-2 justify-center p-4 items-center gap-6 shadow-md  rounded-xl sm:flex-row sm:mx-0 lg:w-[65em] lg:p-0 lg:gap-0">

            <div
                class="lg:text-white  rounded-s-xl h-full flex flex-col justify-around items-center text-center lg:bg-[#2B2B2B]">
                <span class="text-xl tracking-widest my-4 px-6">
                    Une question, un projet ou besoin d'assistance ? <br>Nous sommes à votre écoute pour vous
                    accompagner
                    dans chaque étape.
                </span>

                <Divider class="desktop-hidden" />
            </div>




            <q-form @submit.prevent="sendMail" @reset=""
                class="flex flex-col justify-between gap-6 text-white bg-white lg:px-10 lg:py-16 rounded-e-xl ">
                <!-- Honeypot Field -->
                <input type="text" v-model="honeypot" style="display: none;" aria-hidden="true" />
                <div class="flex flex-col lg:flex-row justify-between">
                    <q-input v-model="firstName" class="lg:w-[45%]" underlined label="Prénom"
                        hint="Prénom (obligatoire)" lazy-rules
                        :rules="[(val: any) => (val && val.length > 0) || 'ce champ est obligatoire']" />
                    <q-input v-model="lastName" class="lg:w-[45%]" underlined label="Nom" hint="Nom (obligatoire)"
                        lazy-rules :rules="[(val: any) => (val && val.length > 0) || 'ce champ est obligatoire']" />
                </div>

                <q-input v-model="email" underlined class="mb-8" type="email" label="Email"
                    hint="Veuillez saisir votre adresse e-mail (obligatoire)" lazy-rules :rules="emailRules" />
                <q-input type="textarea" v-model="subject" underlined label="Sujet"
                    hint="Veuillez préciser le sujet de votre demande (obligatoire)" lazy-rules
                    :rules="[(val: any) => (val && val.length > 0) || 'Veuillez préciser le sujet de votre demande']" />

                <div class="text-black text-xs">
                    <q-checkbox v-model="bookCall" label="Souhaitez-vous réserver un appel ?" />
                </div>

                <div class="flex justify-start" v-if="!loading">
                    <q-btn label="Envoyer" type="submit" style="background: #3F3F3E"
                        :disable="email.length == 0 || firstName.length == 0 || lastName.length == 0" />
                </div>
                <div class="flex justify-start" v-else>
                    <q-spinner color="white" size="3em" />
                </div>
            </q-form>
        </div>
    </section>
</template>

<style scoped>
section {
    font-family: 'Playfair Display', sans-serif;
}
</style>